import React from 'react'
import SEO from "../../components/theme/seo"
import Layout from "../../components/theme/layout"
import Display from "../../components/display"
import ApparelBagsImg from "../../components/images/promotional/apparel-bags"

export default function ApparelBags() {
    return (
        <>
            <Layout>
                <SEO title="Apparel & Bags" />
                <Display
                    title="Apparel & Bags"
                    description="Strut your stuff! Order your company apparel and more from the PROS and enjoy great selection competitive prices and the one-on-one customer service you deserve."
                    prev="/promotional/tradeshows-events"
                    next="/promotional/health-safety"
                    image={<ApparelBagsImg />}
                />
            </Layout>                
        </>
    )
}
